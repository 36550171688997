import React, { useState } from 'react';
import {
  func, string, arrayOf, shape, oneOfType, bool, number
} from 'prop-types';
import {
  Row, Col, Accordion, AccordionTitle, AccordionBody, Checkbox
} from '@thd-olt-component-react/core-ui';
import ReviewHelper from '../../Review/helpers/ReviewHelper';

const GenerateCheckBox = ({ item, onChange }) => {
  let label;
  if (item.id.includes('rating')) {
    label = `${item.value} Stars (${item.count})`;
  } else if (item.id.includes('age')) {
    label = `${item.label} (${item.count})`;
  } else {
    label = `${item.value} (${item.count})`;
  }
  return (
    <Checkbox
      id={item.id}
      value={item.value}
      label={label}
      onChange={onChange}
      checked={item.checked}
    />
  );
};

export const AdditionalFilter = ({ reviewStatistics, onFilterChange, ratingFilters }) => {
  const {
    ratingDistribution = [], totalReviewCount = 0, profileGenderAgeDistribution = []
  } = reviewStatistics || {};
  let combinedRatingDistribution = [...ratingDistribution, ...profileGenderAgeDistribution];
  combinedRatingDistribution.forEach((rating) => {
    // eslint-disable-next-line no-param-reassign
    ratingFilters.find((rf) => rf.value === rating.ratingValue.toString()).count = rating.count;
  });
  const starFilter = ratingFilters.filter((rf) => rf?.id?.includes('rating'));
  const profileFilter = ratingFilters.filter((rf) => rf?.id?.includes('profile'));
  const genderFilter = ratingFilters.filter((rf) => rf?.id?.includes('gender'));
  const ageFilter = ratingFilters.filter((rf) => rf?.id?.includes('age'));

  const total = totalReviewCount || 0;
  const totalWithCommas = ReviewHelper.numberWithCommas(total);
  const iconObj = {
    position: 'left',
    size: 'sm'
  };
  const onChange = (event) => {
    let trim = event.target.value.toString().split(' ').join('');
    onFilterChange(trim);
  };

  return (
    <div className="ratings-reviews__filteringcriteria">
      <Accordion icon={iconObj}>
        <AccordionTitle className="ratings-reviews__filteringcriteria-title">
          <span className="ratings-reviews__filteringcriteria-bold">
            Filter Reviews By:
          </span>
          Rating, Profile, Gender, Age
          <span className="ratings-reviews__filteringcriteria-bold">
            &nbsp;({totalWithCommas})
          </span>
        </AccordionTitle>
        <AccordionBody>
          <Row>
            <Col xs={4} sm={3} md={2}>
              <Col fallback={12}><strong>Star Rating</strong></Col>
              {starFilter && starFilter.map((item, index) => (
                <GenerateCheckBox item={item} index={index} key={item.id} onChange={onChange} />
              ))}
            </Col>
            <Col xs={4} sm={3} md={2}>
              <Col fallback={12}><strong>Home Improvement Profile</strong></Col>
              {profileFilter && profileFilter.map((item, index) => (
                <GenerateCheckBox item={item} index={index} key={item.id} onChange={onChange} />
              ))}
            </Col>
            <Col xs={4} sm={3} md={2}>
              <Col fallback={12}><strong>Gender</strong></Col>
              {genderFilter && genderFilter.map((item, index) => (
                <GenerateCheckBox item={item} index={index} key={item.id} onChange={onChange} />
              ))}
            </Col>
            <Col xs={4} sm={3} md={2}>
              <Col fallback={12}><strong>Age Range</strong></Col>
              {ageFilter && ageFilter.map((item, index) => (
                <GenerateCheckBox item={item} index={index} key={item.id} onChange={onChange} />
              ))}
            </Col>
          </Row>
        </AccordionBody>
      </Accordion>
    </div>
  );

};

AdditionalFilter.displayName = 'RatingsAndReviewsAdditionalFilter';

AdditionalFilter.propTypes = {
  onFilterChange: func.isRequired,
  ratingFilters: arrayOf(shape({
    checked: bool,
    id: string,
    value: oneOfType([number, string]),
    count: oneOfType([number, string])
  })),
  reviewStatistics: shape({
    ratingDistribution: arrayOf(shape({
      ratingValue: number,
      count: oneOfType([number, string]),
      ratingPercentage: oneOfType([number, string])
    })),
    verifiedPurchaserStats: number
  }).isRequired
};

AdditionalFilter.defaultProps = {
  ratingFilters: []
};

GenerateCheckBox.propTypes = {
  item: shape({
    id: string,
    value: string,
    count: oneOfType([number, string]),
    label: string,
    checked: bool
  }),
  onChange: func.isRequired
};

GenerateCheckBox.defaultProps = {
  item: {}
};

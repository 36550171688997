import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { bool, string } from 'prop-types';
import { PresentationProvider } from '../../context/PresentationProvider';
import { WriteAReview } from './WriteAReview.component';

export const WriteAReviewWithPresentation = ({ forceCondensedLayout, channel: providedChannel, ...props }) => {
  const { channel } = useContext(ExperienceContext);
  const useCondensedLayout = forceCondensedLayout || providedChannel === 'mobile' || channel === 'mobile';

  return (
    <PresentationProvider useCondensedLayout={useCondensedLayout}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <WriteAReview {...props} />
    </PresentationProvider>
  );
};

WriteAReviewWithPresentation.propTypes = {
  forceCondensedLayout: bool,
  channel: string
};

WriteAReviewWithPresentation.defaultProps = {
  forceCondensedLayout: false,
  channel: ''
};
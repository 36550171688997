import React from 'react';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { Button } from '@thd-olt-component-react/button';
import classNames from 'classnames';
import { Histogram } from '@thd-olt-component-react/histogram';
import { RatingsAndReviewsMobileHeader } from '../Mobile/RatingsAndReviewsMobileHeader';
import { WriteAReview } from '../WriteAReview/WriteAReview.component';
import { dataModel } from '../ReviewDataModel';
import { OverviewDefaultProps, OverviewPropTypes } from './OverviewPropTypes';
import './Overview.style.scss';
import { getSeoLink } from '../../helpers';

// TODO: Rescope CSS
export const OverviewMobile = ({
  apiKey,
  isMobileOverlay,
  itemId,
  reviewStatistics,
  filterCheckboxes,
  onFilterChange,
  selectedSentiment,
  clickableFilters,
  hideSecondaryRatings,
  handleCardClick,
  category,
  storeId,
  seoStoreLink
}) => {
  const { data, error, loading } = useProductDeprecated({
    itemId,
    domains: ['identifiers', 'info', 'reviews'],
    skip: category === 'store'
  });

  if (category === 'product' && ((error && !data) || !data || loading)) {
    return null;
  }

  const seoLink = getSeoLink(category, data?.product?.identifiers?.canonicalUrl, seoStoreLink);

  const { product } = data || {};
  const { reviews } = product || {};
  const totalReviews = parseInt(reviews?.ratingsReviews?.totalReviews, 10) || 0;
  const averageRating = parseFloat(reviews?.ratingsReviews?.averageRating) || 0;
  const {
    averageOverallRating, ratingDistribution, recommendationPercentage, totalReviewCount
  } = reviewStatistics;

  // Use BV as primary but fallback to available product data
  const total = totalReviewCount || totalReviews;
  const average = averageOverallRating || (Math.round(averageRating * 10) / 10);
  const writeAReviewClass = classNames({
    'ratings-and-reviews-overview__desktop__write-review': !isMobileOverlay,
    'ratings-and-reviews-overview__mobile-overlay__write-review': isMobileOverlay
  });
  return (
    <Row>
      <RatingsAndReviewsMobileHeader
        average={average}
        total={total}
        recommendationPercentage={recommendationPercentage}
      />
      {(ratingDistribution || []).length > 0
        && (
          <Col xs="12">
            <Histogram
              ratings={ratingDistribution}
              onFilterChange={onFilterChange}
              filterCheckboxes={filterCheckboxes}
              clickableFilters={clickableFilters}
              selectedSentiment={selectedSentiment}
            />
          </Col>
        )}
      <Col
        fallback={isMobileOverlay ? '12' : '6'}
        sm="6"
        xs={isMobileOverlay ? '12' : '6'}
      >
        {category === 'product' && (
          <div className={writeAReviewClass}>
            <WriteAReview
              apiKey={apiKey}
              reviewStatistics={reviewStatistics}
              itemId={itemId}
              className="overview"
            />
          </div>
        )}
      </Col>
      {!isMobileOverlay
        && (
          <Col fallback="6" xs="6">
            <div className="ratings-and-reviews-overview__desktop__write-review">
              <Button onClick={() => handleCardClick()}>
                <a className="seo_link" onClick={(evt) => evt.preventDefault()} href={`${seoLink}`}>
                  See All Reviews
                </a>
              </Button>
            </div>
          </Col>
        )}
    </Row>
  );
};

OverviewMobile.displayName = 'RatingsAndReviewsOverviewMobile';

OverviewMobile.propTypes = OverviewPropTypes;

OverviewMobile.defaultProps = OverviewDefaultProps;

OverviewMobile.dataModel = dataModel;

import React, { useEffect, useRef, useState } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { ReviewModel } from './models/ReviewModel';
import { ReviewStatsModel } from './models/ReviewStatsModel';
import { pipReviewsPerPage, seoReviewsPerPage } from '../constants';
import {
  getPageSize, getSeoStartIndex, getStartIndex, getProfileFilters, getStarRatings
} from '../helpers';

// TODO: UPDATE DEPRECATED HOOKS
/**
 * This hook calls the review services (BV) and falls back to review data from product model if it fails.
 * @param itemId
 * @param options - RR state which contains filtering options for the BV call
 * @returns { averageRating, loading, FilterSelected, reviewModels, statistics, SortBy, totalReviews } -
 *   based on available data source
 */
export const useStoreReviews = ({ storeId, seoPageNumber, ...options }) => {
  const {
    filters, pageContext, searchText, selectedSentiment, sortBy
  } = options;
  const initialOptions = useRef({});

  let seoStartIndex = getSeoStartIndex(seoPageNumber, seoReviewsPerPage);

  const pageSize = getPageSize(seoPageNumber, seoReviewsPerPage, pipReviewsPerPage);

  const [queryVariables, setQueryVariables] = useState({
    storeId,
    filters: {
      isVerifiedPurchase: false,
      prosCons: null,
      starRatings: null,
      profile: null,
      gender: null,
      ageRange: null
    },
    searchTerm: null,
    sortBy: 'photoreview',
    startIndex: seoStartIndex,
    recfirstpage: pageSize.toString(),
    pagesize: pageSize.toString()
  });

  useEffect(() => {
    if (options && !initialOptions?.current?.pageContext) {
      initialOptions.current = options;
      return;
    }
    if (!options || JSON.stringify(options) === JSON.stringify(initialOptions.current)) return;
    initialOptions.current = options;
    const { currentPage } = pageContext;
    const verifiedFilter = filters.find((eachFilter) => eachFilter.id === 'verified');
    const checkedFilters = filters.filter((eachFilter) => eachFilter.id !== 'verified' && eachFilter.checked);
    const isVerifiedPurchase = verifiedFilter && verifiedFilter.checked;

    const {
      starRatings,
      ageRange,
      profile,
      gender
    } = getProfileFilters(checkedFilters);

    const reviewsPerPage = seoPageNumber ? seoReviewsPerPage : pipReviewsPerPage;

    const reviewQueryFilters = {
      isVerifiedPurchase,
      prosCons: null,
      starRatings,
      ageRange,
      profile,
      gender
    };

    let startIndex = getStartIndex(currentPage, reviewsPerPage);
    const variables = {
      storeId,
      filters: reviewQueryFilters,
      searchTerm: searchText,
      sortBy,
      startIndex,
      recfirstpage: pageSize.toString(),
      pagesize: pageSize.toString()
    };
    setQueryVariables(variables);
  }, [options]);

  const { data, loading, error } = useDataModel('storeReviews', {
    variables: queryVariables
  });

  const storeReviewData = data?.storeReviews;

  if (loading) {
    return { loading: true };
  }

  if (storeReviewData) {
    const { FilterSelected, Results, SortBy } = storeReviewData;
    const results = Results || [];
    const reviewModels = results.map((result) => new ReviewModel(result));
    const statistics = new ReviewStatsModel(storeReviewData);
    return { FilterSelected, reviewModels, statistics, SortBy };
  }

  return {};

};
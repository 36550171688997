import React from 'react';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { RatingMeter } from '@one-thd/sui-atomic-components';
import classNames from 'classnames';
import { Histogram } from '@thd-olt-component-react/histogram';
import { WriteAReview } from '../WriteAReview/WriteAReview.component';
import { dataModel } from '../ReviewDataModel';
import ReviewHelper from '../Review/helpers/ReviewHelper';
import { OverviewDefaultProps, OverviewPropTypes } from './OverviewPropTypes';
import './Overview.style.scss';

// TODO: Rescope CSS
export const OverviewDesktop = ({
  apiKey,
  isMobileOverlay,
  itemId,
  reviewStatistics,
  filterCheckboxes,
  onFilterChange,
  selectedSentiment,
  clickableFilters,
  category,
  disableOverlayOnClick
}) => {
  const { data, error, loading } = useProductDeprecated({
    itemId,
    domains: ['identifiers', 'info', 'reviews'],
    skip: category === 'store'
  });

  if (category === 'product' && ((error && !data) || !data || loading)) {
    return null;
  }

  const { product } = data || {};
  const { reviews } = product || {};
  const totalReviews = reviews?.ratingsReviews?.totalReviews || 0;
  const averageRating = reviews?.ratingsReviews?.averageRating || 0;
  const {
    averageOverallRating,
    ratingDistribution,
    recommendedCount,
    totalRecommendedCount,
    recommendationPercentage,
    totalReviewCount
  } = reviewStatistics || {};

  // Use BV as primary but fallback to available product data
  const total = totalReviewCount || totalReviews;
  const totalWithCommas = ReviewHelper.numberWithCommas(total);
  const average = averageOverallRating || Number(averageRating).toFixed(1);
  const writeAReviewClass = classNames({
    'ratings-and-reviews-overview__desktop__write-review': !isMobileOverlay,
    'ratings-and-reviews-overview__mobile-overlay__write-review': isMobileOverlay
  });

  return (
    <Row>
      <Col xs="3" className="flex">
        <ul className="ratings-and-reviews-overview__desktop__list">
          <li>
            <div className="ratings-and-reviews-overview__desktop__list__review-column">
              <span className="ratings-and-reviews-overview__desktop__list__review-average">{average} </span>
              <span className="ratings-and-reviews-overview__desktop__list__review-stars">
                <RatingMeter
                  className="sui-p-0 sui-justify-start"
                  value={Number(average)}
                  RatingProps={{
                    color: 'brand'
                  }}
                />
                <span
                  className="ratings-and-reviews-overview__desktop__list__review-totals"
                >
                  {`out of ${totalWithCommas} reviews`}
                </span>
              </span>
            </div>
          </li>
          {[null, undefined].indexOf(recommendationPercentage) === -1 && category !== 'store'
            && (
              <li className="ratings-and-reviews-overview__desktop__list__recommend-wrapper">
                <span
                  className="ratings-and-reviews-overview__desktop__list__float-left__recommend-percentage"
                >{recommendationPercentage}%
                </span>
                <span
                  className="ratings-and-reviews-overview__desktop__list__float-left__recommend-customers-text"
                > recommend this {category}
                </span>
              </li>
            )}
        </ul>
      </Col>
      {(ratingDistribution || []).length > 0
        && (
          <Col xs="6">
            <Histogram
              ratings={ratingDistribution}
              onFilterChange={onFilterChange}
              filterCheckboxes={filterCheckboxes}
              clickableFilters={clickableFilters}
              selectedSentiment={selectedSentiment}
            />
          </Col>
        )}
      {category === 'product' && (
        <Col fallback="12" xs="3">
          <div className={writeAReviewClass}>
            <WriteAReview
              apiKey={apiKey}
              reviewStatistics={reviewStatistics}
              itemId={itemId}
              className="overview"
              disableOverlayOnClick={disableOverlayOnClick}
            />
          </div>
        </Col>
      )}
      {category === 'store' && (
        <Col fallback="12" xs="3" className="ratings-and-reviews-overview__desktop__store-recommendation">
          <div className="ratings-and-reviews-overview__desktop__percentage--wrapper">
            <span
              className="ratings-and-reviews-overview__desktop__list__review-average"
            >
              {recommendationPercentage}%
            </span>
            <span
              className="ratings-and-reviews-overview__desktop__percentage--description"
            >
              Of Customers <br />Recommend This Store
            </span>
          </div>
        </Col>
      )}
    </Row>
  );
};

OverviewDesktop.displayName = 'RatingsAndReviewsOverviewDesktop';

OverviewDesktop.propTypes = OverviewPropTypes;

OverviewDesktop.defaultProps = OverviewDefaultProps;

OverviewDesktop.dataModel = dataModel;

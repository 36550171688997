import React from 'react';
import { func, string } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { useReviewSentimentsDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { ReviewSentimentModel } from '../../../models/ReviewSentimentModel';
import { Sentiment, PropTypes as SentimentPropTypes } from './Sentiment';
import { usePresentation } from '../../../../context/PresentationProvider';
import './ReviewSentiments.style.scss';

export const ReviewSentiments = ({ itemId, onSentimentChange, selectedSentiment }) => {
  const { useCondensedLayout } = usePresentation();
  const { data, error, loading } = useReviewSentimentsDeprecated({ itemId, ssr: false });
  if ((error && !data) || !data || loading) {
    return null;
  }
  const { reviewSentiments } = data;
  const sentimentsModel = new ReviewSentimentModel(reviewSentiments || {});

  if (!sentimentsModel.sentiments.length) {
    return null;
  }
  const { conSentiments, proSentiments } = sentimentsModel;

  return (
    <Row className="ratings-reviews-sentiments">
      <Col xs="6">
        <Sentiment
          name="Pros"
          values={proSentiments}
          selectedSentiment={selectedSentiment}
          onClick={(sentiment) => onSentimentChange(sentiment)}
        />
      </Col>
      <Col xs="6" flatten={useCondensedLayout}>
        <Sentiment
          name="Cons"
          values={conSentiments}
          selectedSentiment={selectedSentiment}
          onClick={(sentiment) => onSentimentChange(sentiment)}
        />
      </Col>
    </Row>
  );

};

ReviewSentiments.displayName = 'ReviewSentiments';

ReviewSentiments.propTypes = {
  itemId: string.isRequired,
  onSentimentChange: func.isRequired,
  selectedSentiment: SentimentPropTypes.selectedSentiment
};

ReviewSentiments.defaultProps = {
  selectedSentiment: {}
};
import React, { useContext, useEffect } from 'react';
import { func, string } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';

export const BazaarVoiceWriteAReviewForm = ({ campaignId, onClose, productId }) => {
  const { hosts: { bazaarVoiceApps } } = useContext(ExperienceContext);
  const env = bazaarVoiceApps.includes('staging') ? 'staging' : 'production';
  const bvScriptURL = `https://apps.bazaarvoice.com/deployments/homedepot/bvtest/${env}/en_US/bv.js`;

  // Function to dynamically load the Bazaarvoice SDK
  const loadBazaarvoiceSDK = () => {
    return new Promise((resolve, reject) => {
      // Check if the script is already loaded
      if (document.querySelector(`script[src="${bvScriptURL}"]`)) {
        resolve();
        return;
      }

      // Create and append the script to the document
      const script = document.createElement('script');
      script.src = bvScriptURL;
      script.async = true;
      script.onload = resolve;
      script.onerror = () => reject(new Error('Bazaarvoice SDK failed to load.'));
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    const initializeBazaarvoice = async () => {
      try {
        // Load the Bazaarvoice SDK dynamically
        await loadBazaarvoiceSDK();

        window.BV.configure('global', {
          events: {
            submissionClose(data) {
              // JavaScript code that executes when the Bazaarvoice application is closed.
              onClose();
              return document.querySelector(`[data-bv-product-id="${productId}"]`)?.remove();
            }
          },
        });

        // Initialize the Write-A-Review form
        if (window.BV) {
          window.BV.ui('rr', 'submit_review', {
            productId,
            campaignId,
            container: 'BVSubmissionContainer',
          });
        } else {
          console.error('Bazaarvoice SDK not initialized.');
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    initializeBazaarvoice();
  }, [productId, campaignId]); // Dependency array to reinitialize when props change

  return (
    <div>
      {/* Bazaarvoice will render the Write-A-Review form here */}
      <div id="BVSubmissionContainer" />
    </div>
  );
};

// PropTypes validation
BazaarVoiceWriteAReviewForm.propTypes = {
  campaignId: string.isRequired, // Expecting campaignId to be a required string
  onClose: func.isRequired,
  productId: string.isRequired, // Expecting productId to be a required string
};

export default BazaarVoiceWriteAReviewForm;